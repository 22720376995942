import Box from '@mui/material/Box';
import { Question as QuestionComponent, QuestionFormValues } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SidePopup from '@/components/SidePopup';
import { application, applicationPropertyCreate, PropsOfRoute } from '@/routes';
import { assetsGet, assetsGetAll, propertiesGetAll } from '@/selectors';
import { propertyNewQuestion } from '@/store/actions';
import State from '@/store/state';
import { Asset } from '@/store/types';

const QuestionPaneCreate: React.FC<PropsOfRoute<typeof applicationPropertyCreate>> = ({
	match,
}) => {
	const { assetId, applicationName } = match.params;

	const propertiesById = useSelector(propertiesGetAll);
	const assetsById = useSelector(assetsGetAll);
	const asset = useSelector<State, Asset>((state) => assetsGet(state, assetId));

	const dispatch = useDispatch();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const onError = React.useCallback(() => {
		enqueueSnackbar('Failed to save the question, please try again.');
	}, [enqueueSnackbar]);

	const onSave = React.useCallback(
		async (formValues: QuestionFormValues) => {
			dispatch(
				propertyNewQuestion({ assetId: formValues.subCategory, applicationName, formValues }),
			);
			return true;
		},
		[applicationName, propertyNewQuestion],
	);

	const onSuccess = React.useCallback(() => {
		enqueueSnackbar('Question saved succesfully.');
		history.push(application({ applicationName }));
	}, [enqueueSnackbar, history]);

	return (
		<Box
			data-testid="create-question-panel"
			sx={{ bottom: 0, left: 0, position: 'absolute', right: 500, top: 0, width: 500 }}
		>
			<SidePopup title="Create a new question">
				<QuestionComponent
					l10n={{
						actionSubmit: 'Save',
						fieldChoiceActionAdd: 'Add choice',
						fieldChoiceErrorRequired: 'The choice must contain a value',
						fieldChoiceErrorTooFew: 'At least one choice is required',
						fieldChoiceErrorTooLong: 'The choice text is too long',
						fieldChoiceHelperText: 'Enter an allowed answer to the question',
						fieldChoiceLabel: 'Choice',
						fieldChoiceDescriptionHelperText: 'Explanation of the choice',
						fieldChoiceDescriptionLabel: '( Optional )',
						fieldDateFormatErrorRequired: 'Please select a date format from the list',
						fieldDateFormatHelperText:
							'The date format controls how the date will be displayed in the report',
						fieldDateFormatLabel: 'Date format',
						fieldDescriptionHelperText:
							'A short description of the question. Will be displayed alongside the question.',
						fieldDescriptionLabel: 'Description',
						fieldMultipleHelperText:
							'If you expect the user to provide many different answers to the same question set this to yes. We will allow the user to anser multiple times.',
						fieldMultipleLabel: 'Do you expect the user to answer this question more than once?',
						fieldNotApplicableHelperText:
							'If the user can decide for themselves if the question might not be applicable to answer.',
						fieldNotApplicableLabel: 'Do you expect the user to find this question not applicable?',
						fieldOtherOptionHelperText:
							'Is the user allowed to supply another answer that is not part of the given choices?',
						fieldOtherOptionLabel: 'Can another answer be given?',
						fieldPlaceholderErrorNotUnique: 'This question placeholder already exists',
						fieldPlaceholderErrorRequired: 'A placeholder is required to identify the question',
						fieldPlaceholderErrorTooLong: 'The given placeholder is too long',
						fieldPlaceholderHelperText:
							'We use this tag to refer to the data entered by your user as well as creating a placeholder for that data in the report. When you create a question we automatically create a Placeholder for you.',
						fieldPlaceholderLabel: 'Placeholder',
						fieldSubCategoryErrorInvalidSelection:
							'You have selected a value that is not in the list',
						fieldSubCategoryErrorRequired: 'Please select a sub-category for this question',
						fieldSubCategoryHelperText:
							'You can assign the question to a sub category. We use this sub category to help your user navigate your questions, You can create and edit sub category titles.',
						fieldSubCategoryLabel: 'Sub category',
						fieldTitleErrorNotUnique: 'This question already exists',
						fieldTitleErrorRequired: 'Text for the question is required',
						fieldTitleErrorTooLong: 'The question is too long',
						fieldTitleHelperText: 'By asking a question we collect the data for your report.',
						fieldTitleLabel: 'Question',
						fieldTypeErrorInvalidSelection: 'Please select a type from the list',
						fieldTypeErrorRequired: 'A question type is required',
						fieldTypeHelperText: 'This helps us to show the right input type to your user',
						fieldTypeLabel: 'Input type',
						groupChoicesDescription:
							'Manage the choices that are available as answers to this question',
						groupChoicesTitle: 'What are the allowed answers?',
						groupIdentityDescription:
							'To be able to refer to the data in your template you create a Placeholder or you can accept the tag we have generated for you.',
						groupIdentityTitle: 'How do you want to identify the question?',
						groupOptionsDescription:
							"Below is a list of settings which could each influence how the question is answered. Please check each setting's individual description for more information.",
						groupOptionsTitle: 'How do you want the question to be answered?',
						groupQuestionDescription:
							"In order to provide data for your report you'll ask some questions. Apart from the question itself we'll need to know in what way you want to collect the data (input type). Finally, you can organise your questions by putting them in categories.",
						groupQuestionTitle: 'What do you want to ask the user?',
						questionTypes: {
							DATE: 'Date',
							DECIMAL: 'Number',
							IMAGE: 'Image',
							LOCATIONS: 'Locations',
							MULTI_ASSET_REFERENCE: '',
							MULTI_SELECT: 'Multiple select',
							MULTI_SUBSTANCE: '',
							RICH_TEXT: 'Rich text',
							SINGLE_ASSET_REFERENCE: '',
							SINGLE_SELECT: 'Single select',
							SINGLE_SUBSTANCE: '',
							STRING: 'Text',
						},
					}}
					onError={onError}
					onSave={onSave}
					onSuccess={onSuccess}
					questions={Object.values(propertiesById)}
					questionTypesSupported={[
						'DATE',
						'DECIMAL',
						'IMAGE',
						'LOCATIONS',
						'MULTI_SELECT',
						'RICH_TEXT',
						'SINGLE_SELECT',
						'STRING',
					]}
					subCategories={Object.values(assetsById)}
					subCategory={asset}
				/>
			</SidePopup>
		</Box>
	);
};

export default QuestionPaneCreate;
