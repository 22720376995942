import { Box, Paper, Tooltip } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { FormikConfig, useFormik } from 'formik';
import React from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import * as yup from 'yup';

import { ApiAssetType, ApiChoice, ApiProperty } from '../../net/swagger';

export interface Choice {}

const dateFormats = [
	'yyyy-MM-dd',
	'yyyy/MM/dd',
	'dd-MM-yyyy',
	'dd/MM/yyyy',
	'dd MMM yyyy',
	'dd MMMM yyyy',
] as const;
type DateFormat = (typeof dateFormats)[number];

export interface FormValues {
	choices: ApiChoice[];
	dateFormat?: DateFormat;
	description: ApiProperty['description'];
	multiple: boolean;
	notApplicable: boolean;
	otherOption: boolean;
	placeholder: ApiProperty['placeholder'];
	subCategory: ApiAssetType['id'];
	title: ApiProperty['name'];
	type: ApiProperty['type'];
}

export interface QuestionFooterProps {
	formik: ReturnType<typeof useFormik<FormValues>>;
}

export interface QuestionProps {
	canUpdateType?: boolean;
	choices?: ApiChoice[];
	Footer?: React.FC<QuestionFooterProps>;
	l10n: {
		actionSubmit: string;
		fieldChoiceActionAdd: string;
		fieldChoiceErrorRequired: string;
		fieldChoiceErrorTooFew: string;
		fieldChoiceErrorTooLong: string;
		fieldChoiceHelperText: string;
		fieldChoiceLabel: string;
		fieldChoiceDescriptionHelperText: string;
		fieldChoiceDescriptionLabel: string;
		fieldDateFormatErrorRequired: string;
		fieldDateFormatHelperText: string;
		fieldDateFormatLabel: string;
		fieldDescriptionHelperText: string;
		fieldDescriptionLabel: string;
		fieldMultipleHelperText: string;
		fieldMultipleLabel: string;
		fieldNotApplicableHelperText: string;
		fieldNotApplicableLabel: string;
		fieldOtherOptionHelperText: string;
		fieldOtherOptionLabel: string;
		fieldPlaceholderErrorNotUnique: string;
		fieldPlaceholderErrorRequired: string;
		fieldPlaceholderErrorTooLong: string;
		fieldPlaceholderHelperText: string;
		fieldPlaceholderLabel: string;
		fieldSubCategoryErrorInvalidSelection: string;
		fieldSubCategoryErrorRequired: string;
		fieldSubCategoryHelperText: string;
		fieldSubCategoryLabel: string;
		fieldTitleErrorNotUnique: string;
		fieldTitleErrorRequired: string;
		fieldTitleErrorTooLong: string;
		fieldTitleHelperText: string;
		fieldTitleLabel: string;
		fieldTypeErrorInvalidSelection: string;
		fieldTypeErrorRequired: string;
		fieldTypeHelperText: string;
		fieldTypeLabel: string;
		groupChoicesDescription: string;
		groupChoicesTitle: string;
		groupIdentityDescription: string;
		groupIdentityTitle: string;
		groupOptionsDescription: string;
		groupOptionsTitle: string;
		groupQuestionDescription: string;
		groupQuestionTitle: string;
		questionTypes: Record<ApiProperty['type'], string>;
	};
	onError(): void;
	onSave(values: FormValues): Promise<boolean>;
	onSuccess(): void;
	question?: ApiProperty;
	questions: ApiProperty[];
	questionTypesSupported: ApiProperty['type'][];
	subCategories: ApiAssetType[];
	subCategory?: ApiAssetType;
}

const Question: React.FC<QuestionProps> = ({
	canUpdateType = true,
	choices,
	Footer,
	l10n,
	onError,
	onSave,
	onSuccess,
	question,
	questions,
	questionTypesSupported,
	subCategories,
	subCategory,
}) => {
	const initialValues = React.useMemo<FormValues>(
		() => ({
			choices: choices ?? [],
			description: question?.description ?? '',
			multiple: question?.allowMultipleAnswers ?? false,
			notApplicable: question?.hasNotApplicableOption ?? false,
			otherOption: question?.hasOtherOption ?? false,
			placeholder: question?.placeholder ?? '',
			subCategory: subCategory?.id ?? '',
			title: question?.question || question?.name || '',
			type: question?.type ?? 'STRING',
		}),
		[],
	);

	const onSubmit: FormikConfig<FormValues>['onSubmit'] = React.useCallback(
		async (values, formikBag) => {
			formikBag.setSubmitting(true);
			try {
				const isSuccess = await onSave({
					...values,
					dateFormat: values.type === 'DATE' ? values.dateFormat || 'yyyy-MM-dd' : undefined,
				});
				if (isSuccess) {
					onSuccess();
				}
			} catch (error) {
				onError();
			} finally {
				formikBag.setSubmitting(false);
			}
		},
		[onError, onSave, onSuccess],
	);

	const validationSchema = yup.object<FormValues>().shape({
		choices: yup
			.array()
			.of(
				yup.object<ApiChoice>().shape({
					name: yup
						.string()
						.required(l10n.fieldChoiceErrorRequired)
						.max(32, l10n.fieldChoiceErrorTooLong),
					description: yup.string().nullable(),
				}),
			)
			.when('type', ([type], schema) =>
				['MULTI_SELECT', 'SINGLE_SELECT'].includes(type)
					? schema.min(1, 'ERROR_CHOICES_LENGTH')
					: schema,
			),
		dateFormat: yup
			.string()
			.when('type', ([type], schema) =>
				type === 'DATE' ? schema.oneOf(dateFormats, l10n.fieldDateFormatErrorRequired) : schema,
			),
		description: yup.string(),
		multiple: yup.boolean(),
		notApplicable: yup.boolean(),
		otherOption: yup.boolean(),
		placeholder: yup
			.string()
			.max(255, l10n.fieldPlaceholderErrorTooLong)
			.required(l10n.fieldPlaceholderErrorRequired)
			.test(
				'unique',
				l10n.fieldPlaceholderErrorNotUnique,
				(value) =>
					!questions
						.filter(({ id }) => id !== question?.id)
						.map(({ placeholder }) => placeholder)
						.includes(value),
			),
		relatedFiles: yup.boolean(),
		subCategory: yup
			.string()
			.oneOf(
				subCategories.map((potentialSubCategory) => potentialSubCategory.id),
				l10n.fieldSubCategoryErrorInvalidSelection,
			)
			.required(l10n.fieldSubCategoryErrorRequired),
		title: yup
			.string()
			.max(255, l10n.fieldTitleErrorTooLong)
			.required(l10n.fieldTitleErrorRequired)
			.test(
				'unique',
				l10n.fieldTitleErrorNotUnique,
				(value) =>
					!questions
						.filter(({ id }) => id !== question?.id)
						.map(({ question: questionText }) => questionText)
						.includes(value),
			),
		type: yup
			.string()
			.oneOf(
				['STRING', 'DECIMAL', 'SINGLE_SELECT', 'MULTI_SELECT', 'IMAGE', 'LOCATIONS', 'DATE'],
				l10n.fieldTypeErrorInvalidSelection,
			)
			.required(l10n.fieldTypeErrorRequired),
	});

	const formik = useFormik<FormValues>({
		initialValues,
		onSubmit,
		validationSchema,
	});

	const fieldHasError = React.useCallback(
		(fieldName: string): boolean => {
			if (fieldName.includes('.')) {
				const [arrayName, arrayIndex, arrayItemFieldName] = fieldName.split('.');
				return Boolean(
					// @ts-ignore
					formik.errors[arrayName] &&
						// @ts-ignore
						formik.errors[arrayName][arrayIndex] &&
						// @ts-ignore
						formik.errors[arrayName][arrayIndex][arrayItemFieldName] &&
						// @ts-ignore
						formik.touched[arrayName] &&
						// @ts-ignore
						formik.touched[arrayName][arrayIndex] &&
						// @ts-ignore
						formik.touched[arrayName][arrayIndex][arrayItemFieldName],
				);
			}
			// @ts-ignore
			return Boolean(formik.errors[fieldName] && formik.touched[fieldName]);
		},
		[formik],
	);

	const fieldHelperText = React.useCallback(
		(fieldName: string, helperText: string): string => {
			if (fieldHasError(fieldName)) {
				if (fieldName.includes('.')) {
					const [arrayName, arrayIndex, arrayItemFieldName] = fieldName.split('.');
					// @ts-ignore
					return formik.errors[arrayName][arrayIndex][arrayItemFieldName];
				}
				// @ts-ignore
				return formik.errors[fieldName] as string;
			}
			return helperText;
		},
		[fieldHasError, formik],
	);

	// keep track of which choices has the optional explanation field
	const [showChoiceExplanationField, setShowChoiceExplanationField] = React.useState<
		Record<ApiChoice['id'], boolean>
	>(() =>
		formik.values.choices.reduce((acc, choice) => {
			acc[choice.id] = choice.description !== null && choice.description !== '';
			return acc;
		}, {} as Record<ApiChoice['id'], boolean>),
	);

	const onChoiceAdd = React.useCallback(() => {
		const emptyChoice: ApiChoice = {
			computed: false,
			dependentPropertyIds: [],
			description: null,
			id: v4(),
			name: '',
			position: formik.values.choices.length,
			reportText: null,
			reportTextSource: 'ANSWER',
			stableId: v4(),
		};
		formik.setFieldValue(`choices.${formik.values.choices.length}`, emptyChoice);

		// Add a new entry for the showChoiceExplanationField with default `false`
		setShowChoiceExplanationField((prevState) => ({
			...prevState,
			[emptyChoice.id]: false,
		}));
	}, [formik]);

	const onChoiceDelete = React.useCallback(
		async (choice: ApiChoice) => {
			const index = formik.values.choices.findIndex(
				(potentialChoice) => potentialChoice.id === choice.id,
			);
			const fieldHelpers = formik.getFieldHelpers(`choices.${index}`);
			fieldHelpers.setError(undefined);
			fieldHelpers.setTouched(false);
			fieldHelpers.setValue('');
			// find the index.
			// remove the error and touched values, too
			formik.setFieldValue(
				'choices',
				formik.values.choices.filter((existingChoice) => existingChoice.id !== choice.id),
			);

			setShowChoiceExplanationField((prevState) => {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				const { [choice.id]: _, ...newState } = prevState;
				return newState;
			});
		},
		[formik],
	);

	const onChoiceReOrder: OnDragEndResponder = React.useCallback<OnDragEndResponder>(
		(result) => {
			if (!result.destination) {
				return;
			}
			const newChoices: ApiChoice[] = [...formik.values.choices];
			newChoices.splice(result.destination.index, 0, newChoices.splice(result.source.index, 1)[0]);
			formik.setFieldValue(
				'choices',
				newChoices.map((newChoice, index) => ({ ...newChoice, position: index })),
			);
		},
		[formik],
	);

	const showExplanationFieldClickHandler = (choiceId: ApiChoice['id']) => {
		setShowChoiceExplanationField((prevState) => ({
			...prevState,
			[choiceId]: !prevState[choiceId],
		}));
	};

	return (
		<form data-testid="question__form" onSubmit={formik.handleSubmit}>
			<FormControl fullWidth margin="normal">
				<FormLabel sx={{ marginBottom: 2 }}>{l10n.groupQuestionTitle}</FormLabel>
				<Alert severity="info" sx={{ marginBottom: 1 }}>
					{l10n.groupQuestionDescription}
				</Alert>

				<TextField
					error={fieldHasError('title')}
					fullWidth
					helperText={fieldHelperText('title', l10n.fieldTitleHelperText)}
					inputProps={{ 'data-testid': 'question__title' }}
					label={l10n.fieldTitleLabel}
					margin="dense"
					name="title"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					value={formik.values.title}
				/>

				<TextField
					disabled={!canUpdateType}
					error={fieldHasError('type')}
					fullWidth
					helperText={fieldHelperText('type', l10n.fieldTypeHelperText)}
					inputProps={{ 'data-testid': 'question__type' }}
					label={l10n.fieldTypeLabel}
					margin="dense"
					name="type"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					select
					value={formik.values.type}
				>
					{questionTypesSupported.map((questionType) => (
						<MenuItem key={questionType} value={questionType}>
							{l10n.questionTypes[questionType]}
						</MenuItem>
					))}
				</TextField>

				<TextField
					error={fieldHasError('description')}
					fullWidth
					helperText={fieldHelperText('description', l10n.fieldDescriptionHelperText)}
					inputProps={{ 'data-testid': 'question__description' }}
					label={l10n.fieldDescriptionLabel}
					margin="dense"
					name="description"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					value={formik.values.description}
				/>

				{formik.values.type === 'DATE' && (
					<TextField
						error={fieldHasError('dateFormat')}
						fullWidth
						helperText={fieldHelperText('dateFormat', l10n.fieldDateFormatHelperText)}
						inputProps={{ 'data-testid': 'question__date-format' }}
						label={l10n.fieldDateFormatLabel}
						margin="dense"
						name="dateFormat"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						select
						SelectProps={{ native: true }}
						value={formik.values.dateFormat}
					>
						<option value="yyyy-MM-dd">2022-08-15</option>
						<option value="yyyy/MM/dd">2022/08/15</option>
						<option value="dd-MM-yyyy">15-08-2022</option>
						<option value="dd/MM/yyyy">15/08/2022</option>
						<option value="dd MMM yyyy">15 Aug 2022</option>
						<option value="dd MMMM yyyy">15 August 2022</option>
					</TextField>
				)}

				<TextField
					error={fieldHasError('subCategory')}
					fullWidth
					helperText={fieldHelperText('subCategory', l10n.fieldSubCategoryHelperText)}
					inputProps={{ 'data-testid': 'question__sub-category' }}
					label={l10n.fieldSubCategoryLabel}
					margin="dense"
					name="subCategory"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					select
					value={formik.values.subCategory}
				>
					{subCategories.map((potentialSubCategory) => (
						<MenuItem
							disabled={potentialSubCategory.fixed}
							key={potentialSubCategory.id}
							value={potentialSubCategory.id}
						>
							{potentialSubCategory.name}
						</MenuItem>
					))}
				</TextField>
			</FormControl>

			{['MULTI_SELECT', 'SINGLE_SELECT'].includes(formik.values.type) && (
				<DragDropContext onDragEnd={onChoiceReOrder}>
					<Droppable droppableId="choices">
						{(droppableProvided) => (
							<FormControl
								{...droppableProvided.droppableProps}
								fullWidth
								margin="normal"
								ref={droppableProvided.innerRef}
							>
								<FormLabel sx={{ marginBottom: 2 }}>{l10n.groupChoicesTitle}</FormLabel>
								<Alert severity="info" sx={{ marginBottom: 1 }}>
									{l10n.groupChoicesDescription}
								</Alert>
								{formik.values.choices.map((choice, choiceIndex) => (
									<Draggable draggableId={choice.id} index={choiceIndex} key={choice.id}>
										{(draggableProvided) => (
											<div
												{...draggableProvided.draggableProps}
												{...draggableProvided.dragHandleProps}
												ref={draggableProvided.innerRef}
											>
												{showChoiceExplanationField[choice.id] ? (
													<Paper
														sx={{
															display: 'flex',
															flexDirection: 'row',
															flexWrap: 'nowrap',
															alignItems: 'center',
															paddingRight: 1,
															paddingTop: 1,
															marginTop: 1,
															marginBottom: 1,
														}}
													>
														<Box // icon button interfere with drag
															sx={{
																cursor: 'grab',
																p: '8px',
																color: 'grey.600',
															}}
														>
															<DragHandleIcon />
														</Box>
														<div style={{ flex: 1 }}>
															<TextField
																error={fieldHasError(`choices.${choiceIndex}.name`)}
																autoFocus={
																	choiceIndex === formik.values.choices.length - 1 &&
																	choice.name === ''
																}
																InputLabelProps={{
																	shrink: true, // This makes the label stay on top
																}}
																fullWidth
																helperText={fieldHelperText(
																	`choices.${choiceIndex}.name`,
																	l10n.fieldChoiceHelperText,
																)}
																inputProps={{ 'data-testid': 'question__choice' }}
																margin="dense"
																value={formik.values.choices[choiceIndex].name}
																label={l10n.fieldChoiceLabel}
																name={`choices.${choiceIndex}.name`}
																onBlur={formik.handleBlur}
																onChange={formik.handleChange}
															/>
															<TextField
																error={fieldHasError(`choices.${choiceIndex}.description`)}
																fullWidth
																helperText={l10n.fieldChoiceDescriptionHelperText}
																margin="dense"
																value={formik.values.choices[choiceIndex].description}
																label={l10n.fieldChoiceDescriptionLabel}
																name={`choices.${choiceIndex}.description`}
																onBlur={formik.handleBlur}
																onChange={formik.handleChange}
															/>
														</div>

														<IconButton
															sx={{
																':hover': {
																	color: 'warning.main',
																},
															}}
															data-testid="question__choice__action--delete"
															onClick={() => onChoiceDelete(choice)}
														>
															<DeleteIcon />
														</IconButton>
													</Paper>
												) : (
													<TextField
														error={fieldHasError(`choices.${choiceIndex}.name`)}
														autoFocus={
															choiceIndex === formik.values.choices.length - 1 && choice.name === ''
														}
														fullWidth
														helperText={fieldHelperText(
															`choices.${choiceIndex}.name`,
															l10n.fieldChoiceHelperText,
														)}
														inputProps={{ 'data-testid': 'question__choice' }}
														// eslint-disable-next-line react/jsx-no-duplicate-props
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Tooltip title="Click to specify optional alternate text">
																		<IconButton
																			onClick={() => showExplanationFieldClickHandler(choice.id)}
																			data-testid="question__choice__action--expand"
																		>
																			<KeyboardDoubleArrowDownIcon />
																		</IconButton>
																	</Tooltip>
																	<IconButton
																		sx={{
																			':hover': {
																				color: 'warning.main',
																			},
																		}}
																		data-testid="question__choice__action--delete"
																		onClick={() => onChoiceDelete(choice)}
																	>
																		<DeleteIcon />
																	</IconButton>
																</InputAdornment>
															),
															startAdornment: (
																<InputAdornment
																	position="start"
																	{...draggableProvided.dragHandleProps}
																>
																	<DragHandleIcon />
																</InputAdornment>
															),
														}}
														label={l10n.fieldChoiceLabel}
														margin="dense"
														name={`choices.${choiceIndex}.name`}
														onBlur={formik.handleBlur}
														onChange={formik.handleChange}
														value={formik.values.choices[choiceIndex].name}
													/>
												)}
											</div>
										)}
									</Draggable>
								))}

								{droppableProvided.placeholder}

								<Button
									color="secondary"
									data-testid="question__choice__add"
									fullWidth
									onClick={onChoiceAdd}
									variant="outlined"
									sx={{ mt: 1 }}
								>
									{l10n.fieldChoiceActionAdd}
								</Button>

								{typeof formik.errors.choices === 'string' && formik.touched.choices && (
									<FormHelperText error>{l10n.fieldChoiceErrorTooFew}</FormHelperText>
								)}
							</FormControl>
						)}
					</Droppable>
				</DragDropContext>
			)}

			<FormControl fullWidth margin="normal">
				<FormLabel sx={{ marginBottom: 2 }}>{l10n.groupIdentityTitle}</FormLabel>
				<Alert severity="info" sx={{ marginBottom: 1 }}>
					{l10n.groupIdentityDescription}
				</Alert>

				<TextField
					error={fieldHasError('placeholder')}
					fullWidth
					helperText={fieldHelperText('placeholder', l10n.fieldPlaceholderHelperText)}
					inputProps={{ 'data-testid': 'question__placeholder' }}
					label={l10n.fieldPlaceholderLabel}
					margin="dense"
					name="placeholder"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					value={formik.values.placeholder}
				/>
			</FormControl>

			<FormControl fullWidth margin="normal">
				<FormLabel sx={{ marginBottom: 2 }}>{l10n.groupOptionsTitle}</FormLabel>
				<Alert severity="info" sx={{ marginBottom: 1 }}>
					{l10n.groupOptionsDescription}
				</Alert>

				<FormGroup sx={{ marginBottom: 1 }}>
					<FormControlLabel
						control={
							<Switch
								checked={formik.values.multiple}
								name="multiple"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
							/>
						}
						label={l10n.fieldMultipleLabel}
						labelPlacement="start"
						sx={{ justifyContent: 'space-between' }}
					/>
					<FormHelperText error={fieldHasError('multiple')}>
						{fieldHelperText('multiple', l10n.fieldMultipleHelperText)}
					</FormHelperText>
				</FormGroup>

				<FormGroup sx={{ marginBottom: 1 }}>
					<FormControlLabel
						control={
							<Switch
								checked={formik.values.notApplicable}
								name="notApplicable"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
							/>
						}
						label={l10n.fieldNotApplicableLabel}
						labelPlacement="start"
						sx={{ justifyContent: 'space-between' }}
					/>
					<FormHelperText error={fieldHasError('notApplicable')}>
						{fieldHelperText('notApplicable', l10n.fieldNotApplicableHelperText)}
					</FormHelperText>
				</FormGroup>

				{['MULTI_SELECT', 'SINGLE_SELECT'].includes(formik.values.type) && (
					<FormGroup sx={{ marginBottom: 1 }}>
						<FormControlLabel
							control={
								<Switch
									checked={formik.values.otherOption}
									name="otherOption"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
								/>
							}
							label={l10n.fieldOtherOptionLabel}
							labelPlacement="start"
							sx={{ justifyContent: 'space-between' }}
						/>
						<FormHelperText error={fieldHasError('otherOption')}>
							{fieldHelperText('otherOption', l10n.fieldOtherOptionHelperText)}
						</FormHelperText>
					</FormGroup>
				)}
			</FormControl>

			{Footer ? <Footer formik={formik} /> : null}

			<Button
				color="primary"
				data-testid="question__save"
				disabled={formik.isSubmitting || formik.isValidating}
				fullWidth
				sx={{ bottom: (theme) => theme.spacing(2), position: 'sticky', zIndex: 1 }}
				type="submit"
				variant="contained"
			>
				{l10n.actionSubmit}
			</Button>
		</form>
	);
};

export default Question;
